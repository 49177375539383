<template>
  <div>

  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "food_send_message",
  data(){
    return{
      user_name: '',
      user_depart: '',
      bdst: ''
    }
  },
  created() {
    const url = window.location.href;
    const cs = url.split('?')[1];
    const jtcs = cs.split("&");
    const code = jtcs[0].split("=")[1];
    const user_id = jtcs[1].split("=")[1];

    this.axios.post('/userinfo/getUserById', (response) =>{
      if (response.obj){
        this.user_name = response.obj.name;
        this.user_depart = response.obj.depart;
        this.bdst = response.obj.bdst;
        this.axios.post('/wxSendMessage/getOpenid', (response) => {
          if (response.obj){
            ElMessage({
              message: '绑定报餐推送消息成功，请退出页面!',
              type: 'success',
            });
          }
        },{
          code: code,
          user_name: this.user_name,
          user_depart: this.user_depart,
          message_type: 1,
          bdst: this.bdst
        })
      }else {
        ElMessage.error(
            "用户有误，请联系科信部!"
        )
      }
    },{
      userid: user_id
    })

  },
  methods:{

  }
}
</script>

<style scoped>

</style>